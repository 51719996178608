import React from "react"
import { graphql } from "gatsby"
import styles from "../styles/job-listing.module.css"
import HeaderMenu from "../components/header/top-header-with-menu/topHeaderWithMenu"
import Jobs from "../components/jobs-components/jobs-listing-items/jobs-listing-items"
import Benefits from "../components/jobs-components/company-offers/offers"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent";

const JobListing = ({ data, location }) => {
  const cookieConsentRef = React.createRef();
  return (
    <div>
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <Helmet htmlAttributes={{ lang: "de" }}>
        <title>{data.datoCmsSeiten.seo.title}</title>
        <meta name="description" content={data.datoCmsSeiten.seo.description} />
      </Helmet>
      <HeaderMenu url={location.href} page="news-listing" />
      <header className={styles.header}>
        {
         data.datoCmsSeiten.header[0].softHeadline &&
         <div className={styles.h1} dangerouslySetInnerHTML=
         {{
              __html: data.datoCmsSeiten.header[0].softHeadline,
         }}></div>
        }
        {
         !data.datoCmsSeiten.header[0].softHeadline && data.datoCmsSeiten.header[0].headline &&
        <h1>{data.datoCmsSeiten.header[0].headline}</h1>
        }
        <div className={styles.p}
        dangerouslySetInnerHTML=
        {{
             __html: data.datoCmsSeiten.header[0].text,
        }}>
        </div>
      </header>
      <main className={`${styles.mainContainer}`}>
      <Jobs  url={location.href} page="news-listing"/>
        <Benefits/>
      </main>
      <Footer cookieConsentRef={cookieConsentRef} />
    </div>
  )
}

export default JobListing

export const query = graphql`
query JobListing {
    datoCmsSeiten(name: {eq: "Jobs"}) {
      seo {
        description
        title
        twitterCard
      }
      header {
        ... on DatoCmsHeaderTextBild {
          id
          headline
          text
          softHeadline
        }
      }
    }
  }
  `