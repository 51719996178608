import React from "react"
import styles from "./jobs-listing-items.module.css"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"

const JobItems = props => {
  return (
    <StaticQuery
      query={graphql`
        query JobsListingItems {
          allDatoCmsStellenangebote {
            nodes {
              id
              slug
              meta {
                publishedAt(formatString: "MMMM YYYY")
                status
              }
              teilzeit
              header {
                headline
                text
              }
            }
          }
        }
      `}
      render={data => {
        return (
        <div
          className={styles.newsContainer}
          style={
            props.page === "news-listing"
              ? { marginTop: "10px", marginBottom: "10px" }
              : {}
          }
        >
          {props.page !== "news-listing" && (
            <a href="/news" className={styles.a}>
              Unsere News
            </a>
          )}
          <div className={styles.allPartsContainer}>
            {data.allDatoCmsStellenangebote.nodes.map((element, i) => {
              if (element.meta.status === "published") {
                return (
                  <Link to={element.slug + "/"} state={{ prevPath: props.url }}>
                    <div key={i} className={styles.shopware}>
                      <div className={styles.shopwareDescription}>
                        <h2 className={styles.h2}>
                          {element.header[0].headline}
                          <span
                            aria-label="more info"
                            className={styles.more}
                          ></span>
                        </h2>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: element.header[0].text,
                          }}
                        ></div>
                        <div className={styles.jobDetailscontainer}>
                          <div>
                            {element.teilzeit === false && (
                              <p>Voll-/teilzeit</p>
                            )}
                            {element.teilzeit === true && <p>teilzeit</p>}
                          </div>
                          <div>
                            <time dateTime={element.meta.publishedAt}>
                              {element.meta.publishedAt}
                            </time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      )}}
    />
  )
}

export default JobItems
